import { Dispatch, SetStateAction } from "react";

export const localStorageKeys = {
  LOGIN: "login",
  LANGUAGE: "language",
  MAXIMIZED: "maximized",
  CUMULATIVE: "cumulative",
  CUMULATIVE_INF: "cumulativeInf",
  AVERAGE: "average",
  AVERAGE_INF: "averageInf",
  REPORT_UNDO: "reportUndo",
  REPORTS_SORT: "reportsSort",
  STATS_SORT: "statsSort",
  RELEVANT_FILTER: "relevantOnly",
};

export const apiUrl =
  globalThis.location.protocol === "http:"
    ? `http://${globalThis.location.hostname}:${+globalThis.location.port + 1}`
    : `https://api.${globalThis.location.hostname}`;

export type SetIrFunc<T> = Dispatch<SetStateAction<T>>;
