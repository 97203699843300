import {
  VStack,
  Heading,
  Link,
  Image,
  HStack,
  Spacer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
  StackProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { HelpText } from "./HelpText";

function AutoImage(props: { image?: string }) {
  const { image } = props;

  if (image) {
    // PopoverContent has rounded="md"
    return (
      <Popover>
        <PopoverTrigger>
          <Image src={image} height="4em" align="right" rounded="md" />
        </PopoverTrigger>
        <PopoverContent position="relative" alignItems="center">
          <Image src={image} rounded="md" />
          <Link
            position="absolute"
            bottom={0}
            href="https://this-person-does-not-exist.com"
            isExternal
          >
            <Text
              fontSize="sm"
              color="white"
              textShadow="0px 0px 1px gray"
              _hover={{ color: "blue", textShadow: "0px 0px 2px white" }}
            >
              Image: this-person-does-not-exist.com
            </Text>
          </Link>
        </PopoverContent>
      </Popover>
    );
  } else {
    return null;
  }
}

/**
 * An inner box; comes in two shades (`nested` or not)
 * @param props
 * @returns
 */
export function InnerBox(props: {
  name?: string;
  colorScheme?: string;
  children: ReactNode;
  nested?: boolean;
  image?: string;
  vSpacing?: string | number;
  alignItems?: StackProps["alignItems"];
}) {
  const {
    name,
    colorScheme = "brand",
    children,
    nested,
    image,
    vSpacing,
    alignItems,
  } = props;
  const { t } = useTranslation("");

  return (
    <VStack
      spacing={{ base: -1, md: 0 }}
      backgroundColor={nested ? `${colorScheme}.50` : `${colorScheme}.200`}
      rounded="xl"
      width="100%"
    >
      {name ? (
        <>
          <Heading
            padding={2}
            paddingBottom={1}
            roundedTop="xl"
            size="xl"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="bold"
            as="h3"
            width="100%"
            paddingRight="auto"
            backgroundColor={`${colorScheme}.700`}
            textColor="myellow.500"
          >
            <HStack width="100%" align="top">
              <span>{t(name)}</span>
              <Spacer />
              <AutoImage image={image} />
            </HStack>
          </Heading>
          <HelpText
            name={name}
            colorScheme={colorScheme}
            paddingLeft={2}
            paddingTop={2}
          />
        </>
      ) : null}
      <VStack
        padding={2}
        width="100%"
        textColor="brand.800"
        spacing={vSpacing}
        alignItems={alignItems}
      >
        {children}
      </VStack>
    </VStack>
  );
}
