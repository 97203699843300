/**
 * Array with at least one element
 */
export type Array1Plus<T> = [T, ...T[]];

/**
 * Returns the x/y pair of the start of the `which`ed piece.
 * Assumes a circle enclosed in an 8x8 square
 * @param which Which piece
 * @param total Of how many
 */
function pieceStart(which: number, total: number): { x: number; y: number } {
  const x = 4 + 4 * Math.sin(((2 * Math.PI) / total) * which);
  const y = 4 - 4 * Math.cos(((2 * Math.PI) / total) * which);
  return { x, y };
}

export function BinaryPie(props: {
  color: string | Array1Plus<string>;
  bgColor: string;
  pieces: Array1Plus<number | boolean>;
  width: string;
  height: string;
}) {
  const { color, bgColor, pieces, width, height } = props;
  const colors: Array1Plus<string> =
    typeof color === "string" ? [color] : color;
  const length = pieces.length;

  return (
    <svg width={width} height={height} viewBox="0 0 8 8">
      <>
        {bgColor && <circle cx="4" cy="4" r="4" fill={bgColor} stroke="none" />}
        {pieces.map((p, i) => {
          if (p) {
            const start = pieceStart(i, length);
            const end = pieceStart(i + 1, length);
            return (
              <>
                <path
                  d={`M4 4L${start.x} ${start.y}A4 4,0,0,1,${end.x} ${end.y}z`}
                  fill={colors[i % colors.length]}
                />
                <path
                  d={`M${start.x} ${start.y}L4 4 ${end.x} ${end.y}`}
                  fill="none"
                  stroke={bgColor}
                  strokeWidth="0.2"
                />
              </>
            );
          } else {
            return null;
          }
        })}
      </>
    </svg>
  );
}
