import { z } from "zod";
import { Zorm, useZorm } from "react-zorm";
import { FieldGetter, ErrorGetter } from "react-zorm/dist/types";

/* For simple mocking without react */
export const mockable = { useZorm };

export type SchemaZorm<S extends z.ZodTypeAny> = Zorm<S> & {
  schema: S;
};
export type DataZorm<S extends z.ZodTypeAny> = {
  fields: Zorm<S>["fields"];
  errors: Zorm<S>["errors"];
  schema: S;
  data: z.infer<S>;
};
export type OptionalData<T extends { data: any }> = Omit<T, "data"> &
  Partial<Pick<T, "data">>;

export function dataZorm<S extends z.ZodTypeAny>(
  zorm: SchemaZorm<S>,
  data: z.infer<S>
): DataZorm<S> {
  return {
    fields: zorm.fields,
    errors: zorm.errors,
    schema: zorm.schema,
    data: data,
  };
}

/**
 * Same as `useZorm()`, but also remembers the schema for user code
 * @param formName The name of the form
 * @param schema The schema
 * @param options The useZorm options
 * @returns
 */
export function useSchemaZorm<Schema extends z.ZodType<any>>(
  formName: string,
  schema: Schema,
  options?: Parameters<typeof useZorm>[2]
): SchemaZorm<Schema> {
  return {
    ...mockable.useZorm(formName, schema, options),
    schema,
  };
}

export type SubDataZorm<K extends string, F, E, S extends z.ZodTypeAny> = {
  fields: { [k in K]: F };
  errors: { [k in K]: E };
  schema: {
    _def: { shape: () => { [k in K]: S | z.ZodEffects<S> } };
  } & z.ZodTypeAny;
  data: any;
};
type OptionableRefinableSubDataZorm<
  K extends string,
  F,
  E,
  S extends z.ZodTypeAny
> = {
  fields: { [k in K]: F };
  errors: { [k in K]: E };
  schema: {
    _def: { shape: () => { [k in K]: OptionableRefinable<S> } };
  } & z.ZodTypeAny;
  data: any;
};
type Refinable<S> = S | ZodEffectLike<S>;
type OptionableRefinable<S> =
  | Refinable<S>
  | Refinable<Optionable<S>>
  | Optionable<S>;
/**
 * The outer wrapper can be `ZodEffects` (with `_def.shape`), the inner
 * `ZodOptional`, `ZodNullable`, or `ZodDefault` (with `_def.innerType`).
 */
type ExtractOptionableRefinable<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F,
  E,
  S extends z.ZodTypeAny
> = ZodObjectExpand<Z, K> extends ZodEffectLike<S>
  ? ExtractOptionable<ZodObjectExpand<Z, K>["_def"]["schema"], S>
  : ExtractOptionable<ZodObjectExpand<Z, K>, S>;
type Optionable<S> = S | ZodOptionLike<S>;
type ExtractOptionable<X, S> = X extends ZodOptionLike<S>
  ? X["_def"]["innerType"]
  : X;
type ZodEffectLike<S> = { _def: { schema: S } };
type ZodOptionLike<S> = { _def: { innerType: S } };
type ZodObjectLike<K extends string> = {
  _def: { shape: () => { [k in K]: z.ZodTypeAny } };
};
/**
 * Extract the type of `Z[K]`, with `Z` being a `ZodObject`
 */
type ZodObjectExpand<
  Z extends { schema: ZodObjectLike<K> },
  K extends string
> = ReturnType<Z["schema"]["_def"]["shape"]>[K];
export function reveal<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F,
  E,
  S extends z.ZodTypeAny
>(schema: Z, key: K): S {
  const s = schema.schema._def.shape()[key];
  if ("schema" in s._def) {
    if ("innerType" in s._def.schema._def) {
      return s._def.schema._def.innerType;
    } else {
      return s._def.schema;
    }
  } else {
    if ("innerType" in s._def) {
      return s._def.innerType;
    } else {
      return s;
    }
  }
}
export function disrobe<
  X extends OptionableRefinable<S>,
  S extends z.ZodTypeAny
>(schema: X): S {
  if ("schema" in schema._def) {
    if ("innerType" in schema._def.schema._def) {
      return schema._def.schema._def.innerType;
    } else {
      return schema._def.schema;
    }
  } else {
    if ("innerType" in schema._def) {
      return schema._def.innerType;
    } else {
      return schema as any as S;
    }
  }
}

export function subDataZorm<
  Z extends SubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K],
  E extends Z["errors"][K],
  S extends ReturnType<Z["schema"]["_def"]["shape"]>[K]
>(dz: Z, key: K, dataWhenUndefined?: z.infer<S>) {
  const schema = disrobe(dz.schema)._def.shape()[key];
  return {
    fields: dz.fields[key],
    errors: dz.errors[key],
    schema,
    data:
      // When `dataWhenUndefined` is non-nullist, a non-nullable type is returned
      dataWhenUndefined != null
        ? ((dz.data[key] ?? dataWhenUndefined) as NonNullable<z.infer<S>>)
        : (dz.data[key] as z.infer<S>),
  };
}

export function discDataZorm<
  Z extends SubDataZorm<K, F, E, S>,
  K extends string &
    keyof (
      | ReturnType<Z["schema"]["_def"]["options"][C]["_def"]["shape"]>
      | ReturnType<
          Z["schema"]["_def"]["innerType"]["_def"]["options"][C]["_def"]["shape"]
        >
    ),
  F extends Z["fields"][K],
  E extends Z["errors"][K],
  S extends
    | ReturnType<Z["schema"]["_def"]["options"][C]["_def"]["shape"]>[K]
    | ReturnType<
        Z["schema"]["_def"]["innerType"]["_def"]["options"][C]["_def"]["shape"]
      >[K],
  C extends keyof (
    | Z["schema"]["_def"]["innerType"]["_def"]["options"]
    | Z["schema"]["_def"]["options"]
  ) &
    string
>(dz: Z, choice: C, key: K, dataWhenUndefined?: z.infer<S>) {
  return {
    fields: dz.fields[key],
    errors: dz.errors[key],
    schema: (
      disrobe(dz.schema) as z.ZodDiscriminatedUnion<"choice", string, any>
    )._def.options
      .get(choice)
      ._def.shape()[key],
    data: dz.data[key],
  };
}

export function stringDataZorm<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K] & FieldGetter,
  E extends Z["errors"][K] & ErrorGetter,
  S extends ExtractOptionableRefinable<Z, K, F, E, S>
>(
  dz: Z,
  key: K
): {
  name: string;
  error: z.ZodIssue | undefined;
  defaultValue?: string | undefined;
  maxLength: number | undefined;
} {
  const schema = disrobe(dz.schema)._def.shape()[key];
  const zString: z.ZodString =
    "innerType" in schema ? schema.innerType() : schema;
  return {
    name: dz.fields[key](),
    error: dz.errors[key](),
    defaultValue: dz.data?.[key],
    maxLength: zString.maxLength ?? undefined,
  };
}
export function boolDataZorm<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K] & FieldGetter,
  E extends Z["errors"][K] & ErrorGetter,
  S extends ExtractOptionableRefinable<Z, K, F, E, S>
>(
  dz: Z,
  key: K
): {
  name: string;
  error: z.ZodIssue | undefined;
  defaultChecked?: boolean | undefined;
} {
  return {
    name: dz.fields[key](),
    error: dz.errors[key](),
    defaultChecked: dz.data?.[key],
  };
}
export function enumDataZorm<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K] & FieldGetter,
  E extends Z["errors"][K] & ErrorGetter,
  S extends ExtractOptionableRefinable<Z, K, F, E, S> & z.ZodEnum<C>,
  C extends [X, ...X[]],
  X extends string
>(
  dz: Z,
  key: K
): {
  name: string;
  error: z.ZodIssue | undefined;
  defaultValue: string | undefined;
  choices: C;
} {
  const schemadz: Z & { schema: any } = dz;
  const schema = reveal<Z, K, F, E, z.ZodEnum<C>>(schemadz, key);
  return {
    name: dz.fields[key](),
    error: dz.errors[key](),
    defaultValue: dz.data[key],
    choices: schema.options,
  };
}

export function literalDataZorm<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K] & FieldGetter,
  E extends Z["errors"][K] & ErrorGetter,
  S extends ExtractOptionableRefinable<Z, K, F, E, S> & z.ZodLiteral<C>,
  C extends any
>(
  dz: Z,
  key: K
): {
  name: string;
  error: z.ZodIssue | undefined;
  defaultValue: typeof dz.data[K];
} {
  return {
    name: dz.fields[key](),
    error: dz.errors[key](),
    defaultValue: dz.data[key],
  };
}

export function unionDataZorm<
  Z extends OptionableRefinableSubDataZorm<K, F, E, S>,
  K extends keyof Z["fields"] & string,
  F extends Z["fields"][K] & FieldGetter,
  E extends Z["errors"][K] & ErrorGetter,
  S extends ExtractOptionableRefinable<Z, K, F, E, S> & z.ZodUnion<C>,
  C extends [z.ZodTypeAny, ...z.ZodTypeAny[]]
>(
  dz: Z,
  key: K
): {
  name: string;
  error: z.ZodIssue | undefined;
  defaultValue: typeof dz.data[K];
} {
  return {
    name: dz.fields[key](),
    error: dz.errors[key](),
    defaultValue: dz.data[key],
  };
}
