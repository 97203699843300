import { useEffect } from "react";
import { useLocation } from "../hooks/useLocation";

const MAX_UPDATES = 10;
const DELAY = 50;

let updates = 0;

function stillAtTop() {
  if (globalThis.scrollY > 0 && updates++ < MAX_UPDATES) {
    globalThis.scroll?.({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(stillAtTop, DELAY);
  }
}

export function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    globalThis.scrollTo?.(0, 0);
    setTimeout(stillAtTop, DELAY);
  }, [location]);

  return null;
}
