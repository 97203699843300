import { Text, useInterval, Input, Box } from "@chakra-ui/react";
import { eventSchema } from "shared";
import { useState } from "react";
import { DateText } from "./DateText";
import { OuterBox } from "./OuterBox";
import { DataZorm } from "../util/dataZorm";
import { InnerBox } from "./InnerBox";
import { textInputTheme } from "../App";
import { deptNames } from "./Assignment";

function timeGranularity(timeArray: { at: number }[] | undefined) {
  const now = Date.now();

  const mostRecent = timeArray?.at(-1)?.at ?? 0;
  if (mostRecent + 3600 * 1000 > now) {
    // In the first hour after the most recent entry, update every 30 seconds
    return 30 * 1000;
  } else {
    // Afterwards, check every half an hour for updates
    return 1800 * 1000;
  }
}

export function Discussion(props: { dz: DataZorm<typeof eventSchema> }) {
  const { dz } = props;
  const discussion = dz.data.discussion;
  const [recheck, setRecheck] = useState(0);
  useInterval(() => setRecheck(recheck + 1), timeGranularity(discussion));
  return (
    <OuterBox name="Status (OK)" brighter>
      {discussion
        ? discussion.map((d) =>
            d.what === "assign" ? (
              <Box
                width="100%"
                rounded="xl"
                backgroundColor="myellow.500"
                paddingX={2}
              >
                <Text fontSize="xl">
                  <b>{d.by}</b> (
                  <DateText millis={d.at} />
                  {") weiter an "}
                  <b>
                    {d.text in deptNames
                      ? deptNames[d.text as keyof typeof deptNames]
                      : d.text}
                  </b>
                </Text>
              </Box>
            ) : (
              <InnerBox vSpacing={0}>
                <Text fontSize="100%" width="100%" textColor="brand.50">
                  <b>{d.by}</b> (
                  <DateText millis={d.at} />
                  ):
                </Text>
                <Text textColor="white">{d.text}</Text>
              </InnerBox>
            )
          )
        : null}
      <Input
        size={{ base: "sm", md: "md" }}
        type="text"
        placeholder="Dein Diskussionsbeitrag hier"
        spellCheck="true"
        lang="de"
        name={dz.fields.message()}
        {...textInputTheme}
      />
    </OuterBox>
  );
}
