import React, { ReactElement } from "react";

export class ErrorBoundary extends React.Component {
  state: { hasError: boolean };

  constructor(props: { children: ReactElement }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Unerwarteter Fehler!</h1>;
    }
    return (this.props as any).children as ReactElement;
  }
}
