import { Box, ScaleFade } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ZodIssue } from "zod";

/**
 * A placeholder for the component error messages.
 * Will fade in from 50% size on creation and update.
 * @param props
 * @returns
 */
export function ErrorText(props: { error: ZodIssue | undefined }) {
  const { error } = props;
  const { t } = useTranslation("");
  if (error) {
    // `key` is to ensure that a change in message re-triggers the animation
    return (
      <Box width="100%">
        <ScaleFade in={true} initialScale={0.5} key={error.message}>
          <Box
            rounded="md"
            justifySelf="left"
            width="100%"
            color="red"
            bgColor="gray.200"
            paddingTop={1}
            paddingLeft={2}
          >
            {t(
              [`errors.message.${error.message}`, `errors.code.${error.code}`],
              { defaultValue: error.message }
            )}
          </Box>
        </ScaleFade>
      </Box>
    );
  } else {
    return null;
  }
}
