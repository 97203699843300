import useLocalStorage from "@rehooks/local-storage";
import { z } from "shared";

export function useUndo<T extends z.ZodTypeAny>(
  schema: T,
  localStorageKey: string = "undo"
): [z.infer<T> | null, (undoValue: z.infer<T>) => void, () => void] {
  const [value, setValue, deleteValue] =
    useLocalStorage<z.infer<T>>(localStorageKey);
  const parsed = schema.safeParse(value);
  if (parsed.success) {
    return [parsed.data, setValue, deleteValue];
  } else {
    if (value != null) {
      console.error(
        `Error parsing useUndo(${localStorageKey}): ${parsed.error}: ${value}`
      );
    }
    return [null, setValue, deleteValue];
  }
}

export function undoCreator<T extends z.ZodTypeAny>(
  schema: T,
  localStorageKey: string = "undo"
): () => [z.infer<T> | null, (undoValue: z.infer<T>) => void, () => void] {
  return () => useUndo(schema, localStorageKey);
}
