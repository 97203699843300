import { VStack, Heading, StackProps, HStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { HelpText } from "./HelpText";

/**
 * The top-level boxes
 * @param props
 * @returns
 */
export function OuterBox(props: {
  name: string;
  brighter?: boolean;
  children: ReactNode;
  colorScheme?: string;
  maxWidth?: StackProps["maxWidth"];
  width?: StackProps["width"];
  vSpacing?: StackProps["spacing"];
}) {
  const {
    name,
    colorScheme = "brand",
    children,
    brighter,
    maxWidth,
    width = "100%",
    vSpacing,
  } = props;
  const { t } = useTranslation("");

  return (
    <VStack
      marginY={2}
      rounded="xl"
      backgroundColor={`${colorScheme}.${brighter ? "50" : "50"}`}
      width={width}
      maxWidth={maxWidth}
    >
      <HStack
        roundedTop="xl"
        fontSize={{ base: "2xl", md: "3xl" }}
        backgroundColor={`${colorScheme}.${brighter ? "700" : "800"}`}
        color="myellow.500"
        width="100%"
      >
        <Heading padding={2} paddingBottom={0}>
          {t(name)}
        </Heading>
      </HStack>
      <HelpText name={name} colorScheme={colorScheme} paddingLeft={2} />
      <VStack padding={2} width="100%" spacing={vSpacing}>
        {children}
      </VStack>
    </VStack>
  );
}
