import { Text, Link } from "@chakra-ui/react";
import { InnerBox } from "./InnerBox";

export function SmallPrint() {
  return (
    <InnerBox nested>
      <Text fontSize="xl" lineHeight="120%">
        Die eingereichten Texte und Bilder dürfen vom OK Märlistadt für die
        Publikation in allen ihren Kanälen eingesetzt werden (insbesondere
        Märlipost, Flyer, Webseite, Tourismusbüro).
      </Text>
      <Text fontSize="xl" lineHeight="120%">
        Das OK Märlistadt behält sich vor, die Bilder und Texte ohne Rücksprache
        anzupassen, insbesondere um ein einheitliches Erscheinungsbild und eine
        einheitliche Sprache zu erreichen.
      </Text>
      <Text fontSize="xl" lineHeight="120%">
        Der Einreicher/die Einreicherin bestätigt, die entsprechenden Rechte an
        den Texten und Bildern zu haben und diese für diesen Zweck dem OK
        Märlistadt weiterzugeben.
      </Text>
      <Text fontSize="xl" lineHeight="120%">
        Mit dem Klick auf «Einreichen» akzeptierst Du und alle mit Dir
        Auftretenden unsere{" "}
        <Link
          href="https://www.maerlistadt.ch/impressum-1"
          target="_blank"
          rel="noreferrer"
        >
          aktuellen AGBs gemäss unserer Website
        </Link>
        . Das Einholen allfälliger SUISA-Bewilligungen ist Sache der
        Auftretenden. Wir erlauben keine Aufführungen von nicht durch die
        Auftretenden selbst urheberrechtlich geschützten oder diesem zur
        Aufführung bewilligten Werke. Wir haben keine Kenntnis der Inhalte der
        Auftretenden. Eine allfällige Gebührenentrichtung ist Sache der
        Auftretenden. Wir überbinden jedwelche Forderungen Dritter an die
        Auftretenden. Wir filmen und fotografieren während den Veranstaltungen.
        Mit der Teilnahme an der Veranstaltung erklärst Du und alle mit Dir
        Auftretenden Euch damit einverstanden und überlasst uns das
        unbeschränkte Urheber- und Nutzungsrecht des von uns oder Euch
        aufgenommen Bild-, Ton- und Textmaterials.
      </Text>
      <Text fontSize="xl" lineHeight="120%">
        Wir danken Ihnen für Ihr Engagement an der Märlistadt und freuen uns
        gemeinsam mit Ihnen auf eine grossartige Märlistadt!
      </Text>
    </InnerBox>
  );
}
