import {
  Box,
  chakra,
  CheckboxProps,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useCheckbox,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { z } from "zod";
import { eventSchema } from "shared";
import { textInputTheme } from "../App";
import { DataZorm } from "../util/dataZorm";
import { ErrorText } from "./ErrorText";
import { HelpText } from "./HelpText";
import { InnerBox } from "./InnerBox";

export function DayCheckbox(props: CheckboxProps & { label: string }) {
  const { state, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);
  return (
    <chakra.label cursor="pointer" {...htmlProps} {...getLabelProps()}>
      <Box
        textAlign="center"
        width={{ base: "2em", sm: "2.7em" }}
        height={{ base: "2em", sm: "2.7em" }}
        alignItems="center"
        bg={state.isChecked ? "brand.800" : "brand.99"}
        color={state.isChecked ? "white" : props.color}
        border="2px solid"
        borderColor="brand.800"
        rounded="50%"
        position="relative"
        margin="auto"
      >
        <input {...getInputProps()} hidden />
        <Text
          fontSize={{ base: "sm", sm: "2xl" }}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
        >
          {props.label}
        </Text>
      </Box>
    </chakra.label>
  );
}

export function Day(props: CheckboxProps) {
  const { value } = props;
  const date = value as string;
  const [, m, d] = date.split("-");
  const weekday = new Date(date).getDay();

  const a = "brand.200";
  const b = "brand.100";
  const c = "brand.400";
  const colors = [c, a, a, b, b, b, c];

  return (
    <Td
      color={m !== "12" ? "myellow.500" : undefined}
      backgroundColor={colors[weekday]}
    >
      <DayCheckbox {...props} label={d} />
    </Td>
  );
}

const dayName = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export function December(props: { dz: DataZorm<typeof eventSchema> }) {
  const { dz } = props;
  const times = Object.fromEntries(
    Object.values(dz.data.when).map((e) => [e.date, e.time])
  );
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: Object.keys(times),
  });
  return (
    <InnerBox name="Daten und Zeiten">
      <HelpText name="date" above />
      <TableContainer width="100%">
        <Table size={{ base: "sm", sm: "md" }}>
          <Thead>
            <Tr>
              <Td textAlign="center" fontWeight="bold">
                Mo
              </Td>
              <Td textAlign="center" fontWeight="bold">
                Di
              </Td>
              <Td
                textAlign="center"
                fontWeight="bold"
                backgroundColor="brand.100"
              >
                Mi
              </Td>
              <Td
                textAlign="center"
                fontWeight="bold"
                backgroundColor="brand.100"
              >
                Do
              </Td>
              <Td
                textAlign="center"
                fontWeight="bold"
                backgroundColor="brand.100"
              >
                Fr
              </Td>
              <Td
                textAlign="center"
                fontWeight="bold"
                backgroundColor="brand.400"
              >
                Sa
              </Td>
              <Td
                textAlign="center"
                fontWeight="bold"
                backgroundColor="brand.400"
              >
                So
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td backgroundColor="gray.500" />
              <Td backgroundColor="gray.500" />
              <Day {...getCheckboxProps({ value: "2024-12-04" })} />
              <Day {...getCheckboxProps({ value: "2024-12-05" })} />
              <Day {...getCheckboxProps({ value: "2024-12-06" })} />
              <Day {...getCheckboxProps({ value: "2024-12-07" })} />
              <Day {...getCheckboxProps({ value: "2024-12-08" })} />
            </Tr>
            <Tr>
              <Day {...getCheckboxProps({ value: "2024-12-09" })} />
              <Day {...getCheckboxProps({ value: "2024-12-10" })} />
              <Day {...getCheckboxProps({ value: "2024-12-11" })} />
              <Day {...getCheckboxProps({ value: "2024-12-12" })} />
              <Day {...getCheckboxProps({ value: "2024-12-13" })} />
              <Day {...getCheckboxProps({ value: "2024-12-14" })} />
              <Day {...getCheckboxProps({ value: "2024-12-15" })} />
            </Tr>
            <Tr>
              <Day {...getCheckboxProps({ value: "2024-12-16" })} />
              <Day {...getCheckboxProps({ value: "2024-12-17" })} />
              <Day {...getCheckboxProps({ value: "2024-12-18" })} />
              <Day {...getCheckboxProps({ value: "2024-12-19" })} />
              <Day {...getCheckboxProps({ value: "2024-12-20" })} />
              <Day {...getCheckboxProps({ value: "2024-12-21" })} />
              <Day {...getCheckboxProps({ value: "2024-12-22" })} />
            </Tr>
            <Tr>
              <Day {...getCheckboxProps({ value: "2024-12-23" })} />
              <Day {...getCheckboxProps({ value: "2024-12-24" })} />
              <Day {...getCheckboxProps({ value: "2024-12-25" })} />
              <Day {...getCheckboxProps({ value: "2024-12-26" })} />
              <Day {...getCheckboxProps({ value: "2024-12-27" })} />
              <Day {...getCheckboxProps({ value: "2024-12-28" })} />
              <Day {...getCheckboxProps({ value: "2024-12-29" })} />
            </Tr>
            <Tr>
              <Day {...getCheckboxProps({ value: "2024-12-30" })} />
              <Day {...getCheckboxProps({ value: "2024-12-31" })} />
              <Day {...getCheckboxProps({ value: "2025-01-01" })} />
              <Day {...getCheckboxProps({ value: "2025-01-02" })} />
              <Day {...getCheckboxProps({ value: "2025-01-03" })} />
              <Day {...getCheckboxProps({ value: "2025-01-04" })} />
              <Day {...getCheckboxProps({ value: "2025-01-05" })} />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {value.length && <HelpText name="time" above />}
      {value.sort().map((c, i) => {
        return (
          <DateTimeInput
            key={c}
            dateName={dz.fields.when(i).date()}
            name={dz.fields.when(i).time()}
            date={`${c}`}
            defaultValue={times[`${c}`]}
            error={dz.errors.when(i).time()}
          />
        );
      })}
      <ErrorText error={dz.errors.when()} />
    </InnerBox>
  );
}

export function DateTimeInput(props: {
  dateName: string;
  name: string;
  date: string;
  defaultValue: string;
  error?: z.ZodIssue;
}) {
  const { name, dateName, date, defaultValue, error } = props;
  const [, m, d] = date.split("-");
  const weekday = new Date(date).getDay();
  return (
    <FormControl>
      <HStack>
        <FormLabel
          minWidth="8em"
          marginTop={2}
          fontSize={{ base: "xl", md: "2xl" }}
        >
          <HStack>
            <Text width="1.8em">{`${dayName[weekday]},`}</Text>
            <Text>{`${d}.${m}.:`}</Text>
          </HStack>
        </FormLabel>
        <Input type="hidden" name={dateName} value={date} />
        <Input
          size={{ base: "sm", md: "md" }}
          type="text"
          name={name}
          defaultValue={defaultValue}
          {...textInputTheme}
        />
      </HStack>
      <HelpText name={name} colorScheme="brand" />
      <ErrorText error={error} />
    </FormControl>
  );
}

export function DecemberCalendar(props: { dz: DataZorm<typeof eventSchema> }) {
  const { dz } = props;
  return <December dz={dz} />;
}
