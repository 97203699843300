import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  HStack,
  TableProps,
  Tfoot,
  Spacer,
  keyframes,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import useLocalStorage from "@rehooks/local-storage";
import { useEffect } from "react";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends unknown> {
    prev?: Record<string, Record<string, any>>;
  }
}

const growFrames = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;
const grow = `${growFrames} 0.5s linear`;
const tdGrowFrames = keyframes`
  0%   { padding-top: 0px;  padding-bottom: 0px;  }
  100% { padding-top: 12px; padding-bottom: 12px; }
`;
const tdGrow = `${tdGrowFrames} 0.25s linear`;

const shrinkFrames = keyframes`
  0%   { opacity: 1; }
  100% { opacity: 0; }
`;
const shrink = `${shrinkFrames} 0.5s linear`;
const tdShrinkFrames = keyframes`
  0%   { padding-top: 12px; padding-bottom: 12px; }
  100% { padding-top: 0px;  padding-bottom: 0px; }
`;
const tdShrink = `${tdShrinkFrames} 0.25s linear`;

export type DataTableProps<Data extends object> = {
  data: Data[];
  prevData?: Record<string, Record<string, string>>;
  columns: ColumnDef<Data, any>[];
  initialSort?: SortingState;
  persistSortAs: string;
  size?: TableProps["size"];
  rowWidth?: TableProps["minWidth"];
  reallyDelete?: () => void;
};

export function DataTable<Data extends object>({
  data,
  prevData,
  columns,
  initialSort = [],
  persistSortAs,
  size = "md",
  rowWidth,
  reallyDelete,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = useLocalStorage<SortingState | null>(
    persistSortAs,
    initialSort
  );
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (v) =>
      typeof v === "function" ? setSorting(v(sorting ?? [])) : setSorting(v),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting ?? [],
    },
    meta: { prev: prevData },
  });

  useEffect(() => {
    if (reallyDelete) {
      setTimeout(reallyDelete, 500);
    }
  }, [reallyDelete]);

  return (
    <Table variant="striped" size={size}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: any = header.column.columnDef.meta;
              return (
                <Th
                  color="brand.900"
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  backgroundColor={
                    header.id === sorting?.[0]?.id ? "brand.50" : undefined
                  }
                  minWidth={rowWidth}
                >
                  <HStack spacing={0}>
                    {meta?.isNumeric ? <Spacer /> : null}
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </>
                    <chakra.span pl="1">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </HStack>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => {
          let trAnim: string | undefined = undefined;
          let tdAnim: string | undefined = undefined;
          let opacity = 1;
          let paddingY = "12px";
          if ((row.original as any).modified === "added") {
            trAnim = grow;
            tdAnim = tdGrow;
          } else if ((row.original as any).modified === "deleted") {
            trAnim = shrink;
            tdAnim = tdShrink;
            opacity = 0;
            paddingY = "0px";
          }
          return (
            <Tr key={row.id} animation={trAnim} opacity={opacity}>
              {row.getVisibleCells().map((cell) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    backgroundColor={
                      cell.column.id === sorting?.[0]?.id
                        ? "brand.50"
                        : undefined
                    }
                    animation={tdAnim}
                    paddingTop={paddingY}
                    paddingBottom={paddingY}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
      <Tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <Tr key={footerGroup.id}>
            {footerGroup.headers.map((footer) => {
              const meta: any = footer.column.columnDef.meta;
              return (
                <Th
                  key={footer.id}
                  isNumeric={meta?.isNumeric}
                  color="brand.900"
                >
                  {flexRender(
                    footer.column.columnDef.footer,
                    footer.getContext()
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Tfoot>
    </Table>
  );
}
