import { createContext, ReactNode, useContext, useState } from "react";
import { z } from "zod";
import { localStorageKeys } from "../constants";

// Roles need to stay in sync with server/src/userHandler.ts
export const userRole = [
  "demo",
  "guest",
  "master",
  "dept.1",
  "dept.2",
  "dept.3",
  "dept.4",
  "pub.1",
  "pub.2",
  "pub.3",
  "pub.4",
  "pub.5",
  "pub.6",
] as const;
export type UserRole = typeof userRole[number];

// See also mayModify in server/src/downloadHandler.tsx
export function isAdminRole(role?: string) {
  return userRole.indexOf(role as UserRole) >= 2;
}

export const loginInfoSchema = z.object({
  id: z.string(),
  name: z.string(),
  role: z.enum(userRole), // Not authoritative, just for the client UI
});
export type LoginInfoSchema = z.infer<typeof loginInfoSchema>;

export type LoginState = LoginInfoSchema | null;

export type ResumeState = {
  retry: () => void;
  cancel: () => void;
};
const initialResumeState = {
  retry: () => {},
  cancel: () => {},
} as const;

export type LoginContextType = {
  login: LoginState;
  setLogin: (newState: LoginState) => void;
  resume: ResumeState;
  setResume: (newState: ResumeState) => void;
};

let loginForPeeking: LoginState = null;

/**
 * Query login information from localStorage. Use only internally.
 * Use `peekLogin()`, if you need to access the login information
 * outside a component (which should use `useLogin()`).
 * @returns Login information persisted in localStorage
 */
function queryLogin(): LoginState {
  const str = localStorage.getItem(localStorageKeys.LOGIN) ?? "";
  try {
    const obj = JSON.parse(str);
    const parsed = loginInfoSchema.safeParse(obj);
    if (parsed.success) {
      loginForPeeking = parsed.data;
      return parsed.data;
    } else {
      console.error("Bad localStorage contents: ", parsed);
    }
  } catch (e) {
    console.error("Bad localStorage contents: ", { str });
  }
  loginForPeeking = null;
  return null;
}

/**
 * Do not use in components, as it will not trigger updates!
 * @returns Current state of login
 */
export function peekLogin(): LoginState {
  return loginForPeeking;
}

function persistLogin(login: LoginState) {
  loginForPeeking = login;
  if (!!login) {
    localStorage.setItem(localStorageKeys.LOGIN, JSON.stringify(login));
  } else {
    localStorage.removeItem(localStorageKeys.LOGIN);
  }
}

export const LoginContext = createContext<LoginContextType>({
  login: null,
  setLogin: () => console.error("Calling placeholder setLogin()"),
  resume: initialResumeState,
  setResume: () => console.error("Calling placeholder setResume()"),
});
LoginContext.displayName = "LoginContext";

export function LoginProvider(props: { children: ReactNode }) {
  const [login, setLogin] = useState<LoginState>(queryLogin());
  const [resume, setResume] = useState<ResumeState>(initialResumeState);
  return (
    <LoginContext.Provider
      value={{
        login,
        setLogin: (newState: LoginState) => {
          persistLogin(newState);
          setLogin(newState);
        },
        resume,
        setResume,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
}

// Just to help the callers (otherwise TS will merge the types of the elements)
type UseLoginType = [LoginState, (newState: LoginState) => void];
export function useLogin(): UseLoginType {
  const context = useContext(LoginContext);
  return [context.login, context.setLogin];
}

type UseResumeType = [ResumeState, (newState: ResumeState) => void];
export function useResume(): UseResumeType {
  const context = useContext(LoginContext);
  return [context.resume, context.setResume];
}
