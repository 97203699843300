import { Checkbox, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { checkboxTheme } from "../App";
import { ErrorText } from "./ErrorText";
import { HelpText } from "./HelpText";
import { MaybeTooltip } from "./MaybeTooltip";

export function HelpfulCheckbox(props: {
  name: string;
  error?: z.ZodIssue;
  defaultChecked?: boolean;
  isReadOnly?: boolean;
}) {
  const { name, error, defaultChecked, isReadOnly } = props;
  const { t } = useTranslation("");
  return (
    <>
      <Checkbox
        name={name}
        value="1"
        isReadOnly={isReadOnly}
        defaultChecked={defaultChecked}
        justifySelf="left"
        width="100%"
        {...checkboxTheme}
        alignItems="baseline"
      >
        <VStack width="100%">
          <Text fontSize={{ base: "xl", md: "2xl" }} lineHeight="120%">
            <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>
          </Text>
          <HelpText name={name} paddingBottom={0} />
        </VStack>
      </Checkbox>
      <ErrorText error={error} />
    </>
  );
}
