import { ReactNode } from "react";
import {
  Box,
  Center,
  Checkbox,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Spacer,
  TableContainer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink } from "react-router-dom";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { AssigneeSchema, RoleSchema } from "shared";
import { SummariesSeenSchema, SummarySeenSchema } from "server";
import { CenteredSpinner } from "./CenteredSpinner";
import { useMaximized, useRelevantOnly } from "../hooks/usePersistentBoolean";
import { DataTable } from "./DataTable";
import { MaybeTooltip } from "./MaybeTooltip";
import { trpc } from "../util/trpc";
import { apiUrl, localStorageKeys } from "../constants";
import { ErrorBoundary } from "./ErrorBoundary";
import { LoginState, useLogin } from "../contexts/LoginContext";
import { DownloadIcon } from "@chakra-ui/icons";
import { Array1Plus, BinaryPie } from "./BinaryPie";

function shortRecentDate(millis: number, prefix: string = "") {
  let dateDesc: string;
  const now = Date.now();
  if (millis > now + 60 * 1000) {
    // Future (outside 1 minute time skew)
    dateDesc = "???";
  } else if (millis > now - 12 * 60 * 60 * 1000) {
    // Within past 12 hours
    dateDesc = dayjs(millis).format("HH:mm");
  } else if (millis > now - 180 * 86400 * 1000) {
    // Within past ~6 months: Day
    dateDesc = dayjs(millis).format("DD.MM.");
  } else if (millis > 0) {
    // Older
    dateDesc = dayjs(millis).format("YYYY");
  } else {
    return "—";
  }
  return (
    <Tooltip label={prefix + dayjs(millis).format("llll")}>{dateDesc}</Tooltip>
  );
}

function niceDate(isoDate: string) {
  const [, m, d] = isoDate.split("-");
  return `${d}.${m}.`;
}
function niceDateTime(dt: { date: string; time: string }) {
  return `${niceDate(dt.date)} ${dt.time}`;
}

function compactDateTime(dt: { date: string; time: string }[]) {
  return (
    niceDateTime(dt[0]).replace(/\/.*/, "/…").replace(/-.*/, "-…") +
    (dt.length > 1 ? "+" : "")
  );
}
function compactDateTimes(dt: { date: string; time: string }[]) {
  return dt.map(niceDateTime).join(" — ");
}

export function VanishingTooltip(props: {
  label: string;
  isDisabled?: boolean;
  children: ReactNode;
}) {
  const { label, isDisabled, children } = props;
  if (isDisabled) {
    return <>{children}</>;
  } else {
    return <Tooltip label={label}>{children}</Tooltip>;
  }
}

// Also has conventient alphabetical sorting: new < updated < seen
type SeenState = "new" | "seen" | "updated";

const columnHelper = createColumnHelper<SummarySeenSchema>();

const assigneeColor: Record<AssigneeSchema, string> = {
  new: "red",
  "dept.1": "#ffa000",
  "dept.2": "#ffa000",
  "dept.3": "#ffa000",
  "dept.4": "#ffa000",
  master: "blue",
  pub: "green",
};
export function AssignmentState(props: {
  assignee: AssigneeSchema;
  seen: "new" | "seen" | "updated";
}) {
  const { assignee, seen } = props;
  const { t } = useTranslation("");

  return (
    <Tooltip label={t(`state.${assignee}`) + "; " + t(`seen.${seen}`)}>
      <HStack marginX="auto">
        <svg width="1em" height="1em" viewBox="0 0 8 8">
          {seen === "new" ? (
            <circle cx="4" cy="4" r="4" fill={assigneeColor[assignee]} />
          ) : seen === "seen" ? (
            <circle
              cx="4"
              cy="4"
              r="3.5"
              fill="none"
              stroke-width="1"
              stroke={assigneeColor[assignee]}
            />
          ) : (
            <>
              <path d="M4,0a1,-1 0 0,0 0,8" fill={assigneeColor[assignee]} />
              <circle
                cx="4"
                cy="4"
                r="3.5"
                fill="none"
                stroke-width="1"
                stroke={assigneeColor[assignee]}
              />
            </>
          )}
        </svg>
      </HStack>
    </Tooltip>
  );
}

const nPublishers = 4;

function pieces(pubs: Partial<Record<RoleSchema, 0 | 1>>) {
  const pubArray: Array1Plus<0 | 1> = Array(nPublishers).fill(
    0
  ) as Array1Plus<0>;
  Object.entries(pubs).forEach(([k, v]) => {
    if (k.startsWith("pub.")) {
      pubArray[Number(k.substring(4)) - 1] = v;
    }
  });
  // Array may contain holes; fill them now
  return pubArray;
}

function relevantize(login: LoginState, data: SummariesSeenSchema) {
  if (!login) {
    // Should not happen
    return data;
  } else if (login.role === "master") {
    return data.filter((e) => e.assignee === "master" || e.assignee === "new");
  } else if (login.role.startsWith("pub.")) {
    return data.filter((e) => e.assignee === "pub");
  } else {
    return data.filter((e) => e.assignee === login.role);
  }
}

export function EventList(props: {}) {
  const [login] = useLogin();
  const summariesQuery = trpc.list.get.useQuery(undefined);
  const [maximized, setMaximized] = useMaximized();
  const [relevant, setRelevant] = useRelevantOnly();
  const { t } = useTranslation("");
  dayjs.locale("de");

  const columns = [
    columnHelper.accessor(
      (row) =>
        (row.seen == null
          ? "new"
          : row.seen >= row.lastModified
          ? "seen"
          : "updated") as SeenState,
      {
        id: "Status",
        cell: (info) => {
          const assignee = info.row.original.assignee;
          const modifiedState = info.getValue();
          return <AssignmentState assignee={assignee} seen={modifiedState} />;
        },
        header: () => (
          <MaybeTooltip name="report.state">{t("report.state")}</MaybeTooltip>
        ),
      }
    ),
    columnHelper.accessor("lastModified", {
      id: "lastModified",
      cell: (info) => shortRecentDate(info.getValue()),
      header: () => (
        <MaybeTooltip name="report.date">{t("report.date")}</MaybeTooltip>
      ),
    }),
    columnHelper.accessor((row) => compactDateTime(row.when), {
      id: "when",
      cell: (info) => (
        <Tooltip label={compactDateTimes(info.row.original.when)}>
          {info.getValue()}
        </Tooltip>
      ),
      header: "Wann",
    }),
    columnHelper.accessor("who", {
      id: "who",
      cell: (info) => (
        <Link
          as={RouteLink}
          key={info.row.id}
          href={`/event/${info.row.original.id}`}
          to={`/event/${info.row.original.id}`}
          backgroundColor="none"
        >
          {info.getValue()}
        </Link>
      ),
      header: "Wer",
    }),
    columnHelper.accessor("what", {
      id: "what",
      cell: (info) => info.getValue(),
      header: "Was",
    }),
    columnHelper.accessor("where", {
      id: "where",
      cell: (info) => (
        <Tooltip
          label={
            info.getValue() === "Andere" && info.row.original.elsewhere
              ? info.row.original.elsewhere
              : info.getValue()
          }
        >
          {t(`location.${info.getValue()}`)}
        </Tooltip>
      ),
      header: "Wo",
    }),
    columnHelper.accessor("published", {
      id: "pub",
      cell: (info) => (
        <BinaryPie
          color="#008800"
          bgColor="#cdc"
          pieces={pieces(info.getValue())}
          width="1em"
          height="1em"
        />
      ),
      header: () => <MaybeTooltip name="report.pub">Pub</MaybeTooltip>,
    }),
    columnHelper.accessor("id", {
      cell: (info) => (
        <Link href={`${apiUrl}/zip/${login?.id}/one/${info.getValue()}`}>
          <DownloadIcon />
        </Link>
      ),
      header: () => <MaybeTooltip name="report.dl">DL</MaybeTooltip>,
    }),
  ];

  if (summariesQuery.isLoading) {
    return <CenteredSpinner />;
  } else if (summariesQuery.isError) {
    return <h1>Serverfehler</h1>;
  } else if (summariesQuery.data.length === 0) {
    return <h1>Keine Daten</h1>;
  }

  return (
    <ErrorBoundary>
      <Center
        width={maximized ? "min(100vw, 100%)" : "min(100vw, 40rem)"}
        paddingX={2}
      >
        <VStack
          width="100%"
          maxHeight={maximized ? "80vh" : "max(40rem, 60vh)"}
          paddingBottom="1rem"
          bgColor="brand.100"
          rounded="xl"
        >
          <Flex width="100%">
            <Box width="2rem" />
            <Spacer />
            <Heading marginTop="2rem" textAlign="center">
              {t("report.title")}
            </Heading>
            <Spacer />
            <IconButton
              borderStyle="none"
              aria-label="maximize"
              icon={
                maximized ? (
                  /*
                   * Results in React warnings in the browser:
                   * "Unexpected value 1.5rem parsing height attribute." and
                   * "Unexpected value 1.5rem parsing width attribute.".
                   * However, there does not seem to be another way to make
                   * it the right size…
                   */

                  <FiMinimize size="1.5rem" />
                ) : (
                  <FiMaximize size="1.5rem" />
                )
              }
              onClick={() => {
                setMaximized(!maximized);
              }}
            />
          </Flex>
          <Checkbox
            paddingLeft={2}
            width="100%"
            defaultChecked={relevant}
            onChange={(e) => setRelevant(e.target.checked)}
          >
            <Text color="brand.50">Unwichtige ausblenden</Text>
          </Checkbox>
          {summariesQuery.isLoading ? (
            <CenteredSpinner />
          ) : summariesQuery.isError ? (
            <Text size="xl">{t("report.bad-format")}</Text>
          ) : !summariesQuery || summariesQuery.data.length === 0 ? (
            <Text size="xl">{t("report.no-records")}</Text>
          ) : (
            <TableContainer width="100%" overflowY="auto">
              <DataTable
                columns={columns}
                data={
                  relevant
                    ? relevantize(login, summariesQuery.data)
                    : summariesQuery.data
                }
                persistSortAs={localStorageKeys["REPORTS_SORT"]}
                initialSort={[{ id: "who", desc: true }]}
              />
            </TableContainer>
          )}
          {login ? (
            <VStack width="100%" paddingX={2} spacing={2} alignItems="left">
              <MaybeTooltip name="report.kuchendiagramm">
                <Text
                  lineHeight="110%"
                  maxWidth="100%"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {"Download Programmpunkte: "}
                  <Link href={`${apiUrl}/zip/${login?.id}/all`}>Alle</Link>
                  {" bzw. "}
                  <Link href={`${apiUrl}/zip/${login?.id}/changed`}>
                    geänderte seit letztem Download
                  </Link>
                  .
                </Text>
              </MaybeTooltip>
              <Text
                lineHeight="110%"
                maxWidth="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {"Download Listen: "}
                <Link href={`${apiUrl}/xlsx/${login?.id}/contacts`}>
                  Adressliste
                </Link>
                {"; "}
                <Link href={`${apiUrl}/xlsx/${login?.id}/agenda`}>Agenda</Link>
                {"; "}
                <Link href={`${apiUrl}/ics/${login?.id}/full`}>Kalender</Link>.
              </Text>
            </VStack>
          ) : null}
        </VStack>
      </Center>
    </ErrorBoundary>
  );
}
