import { Box, Link, Text, VStack } from "@chakra-ui/react";
import { isAdminRole, useLogin } from "../contexts/LoginContext";
import { UserLanding } from "../components/UserLanding";
import { AdminLanding } from "../components/AdminLanding";

export function Landing(props: { thanks?: boolean }) {
  const [login] = useLogin();
  const { thanks } = props;
  return (
    <VStack width="100%" margin={0}>
      <Box
        padding="2em"
        backgroundColor="myellow.50"
        width="100%"
        fontSize="xl"
      >
        {isAdminRole(login?.role) ? <AdminLanding /> : <UserLanding thanks={thanks} />}
      </Box>
      <Box
        position="fixed"
        bottom="0"
        width="100%"
        backgroundColor="brand.800"
        padding={1}
        color="brand.50"
        textAlign="center"
      >
        <Text fontSize={{ base: "sm", md: "xl" }}>
          <Link href="https://maerlistadt.ch" color="brand.200">
            Märlistadt™
          </Link>
          {"-Programmplaner; © 2022-2024 "}
          <Link href="https://trifence.ch" color="brand.200">
            Trifence AG, Stein am Rhein
          </Link>
        </Text>
      </Box>
    </VStack>
  );
}
