"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageSchemaWithFile = exports.imageSchema = exports.imageInfoSchema = exports.imageTypes = void 0;
const zod_1 = require("zod");
exports.imageTypes = ["pdf", "svg", "jpeg", "png", "thumb.jpeg"];
exports.imageInfoSchema = zod_1.z.object({
    width: zod_1.z.number(),
    height: zod_1.z.number(),
    bytes: zod_1.z.number(),
});
exports.imageSchema = zod_1.z.object({
    types: zod_1.z.record(zod_1.z.enum(exports.imageTypes), exports.imageInfoSchema).optional(),
});
exports.imageSchemaWithFile = exports.imageSchema.extend({
    file: zod_1.z.string(),
    uploaded: zod_1.z.number().default(Date.now),
    attribution: zod_1.z.string().optional(),
});
