import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { dangerButtonProps } from "../App";
import { useUndo } from "../hooks/useUndo";

/**
 * Present an Undo box
 * Properties:
 * - schema: A Zod Schema
 * - name: The key to use for translation  *and* localStorage
 * - describe: A function describing the undo contents
 * - recoveryUrl: Where to go to for the recovery page
 * @param props Properties
 * @returns
 */
export function UndoBox<T extends z.ZodTypeAny>(props: {
  schema: T;
  name: string;
  describe: (data: z.infer<T>) => string;
  recoveryUrl: string;
}) {
  const { schema, name, describe, recoveryUrl } = props;
  const [undo, , deleteUndo] = useUndo(schema, name);
  const { t } = useTranslation("");

  if (undo) {
    return (
      <Center
        width="min(100vw, 40rem)"
        paddingX={2}
        minHeight="10rem"
        textAlign="center"
      >
        <VStack
          width="100%"
          rounded="xl"
          bgColor="gray.300"
          paddingY="2rem"
          spacing={6}
        >
          <Heading>{t(`${name}.title`)}</Heading>
          <Button as={RouteLink} to={recoveryUrl} border="2px" minWidth="80%">
            <Text>{t(`${name}.recover`, { contents: describe(undo) })}</Text>
          </Button>
          <HStack minWidth="80%" paddingTop={6}>
            <Spacer />
            <Button
              size="sm"
              border="2px"
              {...dangerButtonProps}
              rightIcon={<DeleteIcon />}
              onClick={deleteUndo}
            >
              {t(`${name}.empty`)}
            </Button>
          </HStack>
        </VStack>
      </Center>
    );
  } else {
    return null;
  }
}
