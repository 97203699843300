import { Global, css } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Saira";
        font-stretch: 50% 100%;
        font-weight: 100 900;
        src: url("/Saira.subset.woff2") format("woff2");
        unicode-range: U+20, U+41-5a;
      }
    `}
  />
);

export default Fonts;
