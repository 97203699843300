import { Flex, Spinner, SpinnerProps } from "@chakra-ui/react";

type Props = SpinnerProps;

export function CenteredSpinner(props: Props) {
  return (
    <Flex flex={1} justify="center" align="center" minHeight="100%">
      <Spinner
        size="xl"
        thickness="4px"
        color="brand.700"
        label="Loading..."
        {...props}
      />
    </Flex>
  );
}
