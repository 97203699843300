import { useLocalStorage } from "@rehooks/local-storage";
import { localStorageKeys } from "../constants";

type NumBool = 0 | 1;

function fromBool(v: boolean): NumBool {
  return v ? 1 : 0;
}

function toBool(s: NumBool | null, defaultValue: boolean): boolean {
  if (s === null) {
    return defaultValue;
  } else {
    return s === 1;
  }
}

export function useBooleanLocalStorage(
  key: keyof typeof localStorageKeys,
  defaultValue?: boolean
): [value: boolean, setValue: (newValue: boolean) => void] {
  let [value, setValue] = useLocalStorage<NumBool>(localStorageKeys[key]);
  return [toBool(value, defaultValue ?? false), (v) => setValue(fromBool(v))];
}

export function useMaximized() {
  return useBooleanLocalStorage("MAXIMIZED");
}

export function useRelevantOnly() {
  return useBooleanLocalStorage("RELEVANT_FILTER");
}
