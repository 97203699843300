import {
  Link,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OuterBox } from "./OuterBox";
import calendar from "../assets/calendar.jpeg";
import calendarThumb from "../assets/calendar.thumb.jpeg";
import event from "../assets/event.jpeg";
import eventThumb from "../assets/event.thumb.jpeg";
import music from "../assets/music.jpeg";
import musicThumb from "../assets/music.thumb.jpeg";

export function ImageWithPreview(props: { thumb: string; full: string }) {
  const { thumb, full } = props;

  // PopoverContent has rounded="md"
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Image src={thumb} width="100%" rounded="md" />
      </PopoverTrigger>
      <PopoverContent alignItems="center" width="95%">
        <Image src={full} rounded="md" />
      </PopoverContent>
    </Popover>
  );
}

export function ExampleImages() {
  const { t } = useTranslation("");

  return (
    <OuterBox name="examples" vSpacing={6}>
      <Link
        href="https://www.maerlistadt.ch/galerie/archiv"
        marginTop={-6}
        marginBottom={-3}
      >
        {t("exampleLink")}
      </Link>
      <ImageWithPreview thumb={musicThumb} full={music} />
      <ImageWithPreview thumb={eventThumb} full={event} />
      <ImageWithPreview thumb={calendarThumb} full={calendar} />
    </OuterBox>
  );
}
