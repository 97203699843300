import { HStack, Select, Text } from "@chakra-ui/react";
import { eventSchema } from "shared";
import { useLogin } from "../contexts/LoginContext";
import { DataZorm } from "../util/dataZorm";
import { ErrorText } from "./ErrorText";
import { OuterBox } from "./OuterBox";

export const deptNames = {
  new: "Noch nicht zugewiesen",
  master: "Andrea Kubli",
  "dept.1": "Philipp Schwarzer",
  "dept.2": "Dani Griesser",
  "dept.3": "Nino Alibrando",
  "dept.4": "Ressort Reserve",
  pub: "Publikatoren",
};

export function Assignment(props: { dz: DataZorm<typeof eventSchema> }) {
  const { dz } = props;
  const [login] = useLogin();
  if (!login) {
    return null;
  }
  return (
    <OuterBox name="assign">
      <HStack width="100%" alignItems="baseline">
        <Text minWidth="6em" marginTop={2} fontSize={{ base: "xl", md: "2xl" }}>
          An
        </Text>
        <Select
          fontSize={{ base: "xl", md: "2xl" }}
          key="user"
          name={dz.fields.assignee()}
          placeholder="🠋"
          defaultValue={dz.data.assignee ?? "new"}
          width="100%"
        >
          {Object.keys(deptNames)
            .filter((c) => c !== "")
            .map((c) => (
              <option key={c} value={c}>
                {c in deptNames ? deptNames[c as keyof typeof deptNames] : c}
              </option>
            ))}
        </Select>
      </HStack>
      <ErrorText error={dz.errors.assignee()} />
    </OuterBox>
  );
}
