import i18next from "i18next";
import httpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

type Options = {
  language: string;
};

export function initializeI18n({ language }: Options) {
  const options = {
    lng: language,
    interpolation: {
      escapeValue: false,
    },
  };

  i18next
    .use(httpBackend)
    .use(initReactI18next)
    .init({
      ...options,
      fallbackLng: "en",
      ns: ["irs"],
      backend: { loadPath: "/locales/{{lng}}.json" },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      },
    });
}
