import { KeyboardEvent, useState } from "react";
import {
  HStack,
  Progress,
  Spacer,
  Text,
  Textarea,
  TextareaProps,
  VStack,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

function ignoreReturnHandler(event: KeyboardEvent<HTMLTextAreaElement>) {
  if (event.key === "Enter" || event.key === "Tab") {
    event.preventDefault();
  }
}
export function CountingTextarea(
  props: {
    min: number;
    max: number;
    defaultValue?: string;
    ignoreReturn?: boolean;
  } & TextareaProps
) {
  let { min, max, ignoreReturn, defaultValue, ...rest } = props;
  const [length, setLength] = useState((defaultValue ?? "").length);
  if (ignoreReturn) {
    rest.onKeyDown = ignoreReturnHandler;
  }
  return (
    <VStack alignItems="right">
      <Textarea
        {...rest}
        defaultValue={defaultValue}
        onChange={(event) => setLength(event.target.value.length)}
      />
      <Progress
        value={length}
        colorScheme={
          length < min ? "blackAlpha" : length > max ? "red" : "green"
        }
        max={max}
        hasStripe
      />
      <HStack
        color="brand.50"
        rounded="md"
        width="100%"
        backgroundColor={length > max ? "red.800" : undefined}
        paddingRight={length > max ? 2 : 0}
      >
        <Spacer />
        <Text align="right">{`${length < min ? "Zu kurz: " : ""}${
          length > max ? "Zu lang: " : ""
        }${length} von ${min}…${max} Zeichen`}</Text>
        {length < min || length > max ? <WarningIcon /> : <CheckCircleIcon />}
      </HStack>
    </VStack>
  );
}
