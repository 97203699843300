import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowUpIcon,
  CheckCircleIcon,
  DeleteIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import { Helmet } from "react-helmet";
import {
  EventSchema,
  eventSchema,
  ImageSchemaWithFile,
  RoleSchema,
} from "shared";
import { OuterBox } from "../components/OuterBox";
import { trpc } from "../util/trpc";
import {
  useSchemaZorm,
  dataZorm,
  subDataZorm,
  SchemaZorm,
  stringDataZorm,
  boolDataZorm,
} from "../util/dataZorm";
import { boringButtonProps, dangerButtonProps, textInputTheme } from "../App";
import { TextInput } from "../components/TextInput";
import { DecemberCalendar } from "../components/DecemberCalendar";
import { apiUrl } from "../constants";
import { InnerBox } from "../components/InnerBox";
import { UploadableImage } from "../components/UploadableImage";
import { HelpText } from "../components/HelpText";
import { CountingTextarea } from "../components/CountingTextarea";
import { HelpfulCheckbox } from "../components/HelpfulCheckbox";
import { ErrorText } from "../components/ErrorText";
import { CenteredSpinner } from "../components/CenteredSpinner";
import { useLogin } from "../contexts/LoginContext";
import { useUndo } from "../hooks/useUndo";
import { SmallPrint } from "../components/SmallPrint";
import { Discussion } from "../components/Discussion";
import { Assignment } from "../components/Assignment";

function ValidationStatus(props: { zo: SchemaZorm<typeof eventSchema> }) {
  const { zo } = props;
  const { t } = useTranslation("");

  if (
    zo.validation &&
    (zo.validation as any).error &&
    (zo.validation as any).error.issues
  ) {
    return (
      <Text color="red">
        <WarningTwoIcon />
        {` ${t("errors.counter", {
          count: (zo.validation as any).error.issues.length,
        })} `}
        <ArrowUpIcon />
      </Text>
    );
  } else if (zo.validation?.success) {
    return (
      <Text color="green">
        <CheckCircleIcon /> {t("errors.submit")}
      </Text>
    );
  } else {
    return <Text>&nbsp;</Text>;
  }
}

const emptyEvent: EventSchema = {
  what: "",
  who: "",
  where: "",
  when: [],
  desc: "",
  img1: { file: "", uploaded: Date.now(), types: {} },
  home: "",
  some: "",
  contact: {
    name: "",
    tel: "",
    mail: "",
    street: "",
    town: "",
    future: true,
  },
  comment: "",
  assignee: "new",
};

type NewRouteParams = { kind: "music" | "hut" | "other" };

export function RecoverEvent(props: {}) {
  const [undo] = useUndo(eventSchema, "eventUndo");
  return <EventEditor event={undo!} kind="other" />;
}
export function NewEvent(props: {}) {
  const { kind = "other" } = useParams<NewRouteParams>();
  return <EventEditor event={emptyEvent} kind={kind} />;
}

export function EditEvent(props: {}) {
  const { eventId } = useParams<{ eventId: string }>();
  const toast = useToast();
  const history = useHistory();
  const eventQuery = trpc.event.get.useQuery(eventId ?? "", {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
  });
  if (eventQuery.isLoading) {
    return <CenteredSpinner />;
  } else if (eventQuery.isError) {
    toast({
      status: "error",
      title: "Kann Event nicht laden",
      description: eventQuery.error.message,
    });
    history.goBack();
    return null;
  } else {
    return <EventEditor event={eventQuery.data} kind="other" />;
  }
}

function mayModify(role?: RoleSchema) {
  return (
    role &&
    (role === "master" || role.startsWith("dept.") || role.startsWith("pub."))
  );
}

export function EventEditor(
  props: { event: EventSchema & { _id?: string } } & NewRouteParams
) {
  const { event, kind } = props;
  const [, setUndo] = useUndo(eventSchema, "eventUndo");
  const history = useHistory();
  const [login] = useLogin();
  const createMutation = trpc.event.create.useMutation();
  const updateMutation = trpc.event.update.useMutation();
  const deleteMutation = trpc.event.delete.useMutation();
  const toast = useToast();
  const [where, setWhere] = useState(event.where); // Unvalidated!
  const { t } = useTranslation("");
  const notTiny = useBreakpointValue({ base: false, md: true });
  const [icon, setIcon] = useState<ImageSchemaWithFile | null>(
    event.logo ?? null
  );
  const zo = useSchemaZorm("event", eventSchema, {
    onValidSubmit(e) {
      function onSuccess() {
        toast({
          status: "success",
          title: reportId
            ? "Programmpunkt eingereicht"
            : "Programmpunkt aktualisiert",
          description: t("report.create.submitted_body"),
        });
        if (reportId) {
          history.push("/");
        } else {
          history.push("/thanks");
        }
      }
      e.preventDefault();
      if (reportId) {
        updateMutation.mutate({ ...e.data, _id: reportId }, { onSuccess });
      } else {
        createMutation.mutate(e.data, { onSuccess });
      }
    },
  });
  const dz = dataZorm(zo, event);
  const dzc = subDataZorm(dz, "contact");
  const dzi = subDataZorm(dz, "internal");
  const disabled = zo.validation?.success === false;
  const reportId = event._id;
  const editable = !reportId || login?.role === "master";
  const editableMore = editable || login?.role.startsWith("dept.");

  function validationButtons() {
    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        width="100%"
        marginBottom={6}
      >
        <Button
          disabled={disabled}
          type="submit"
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          border="solid 3px"
        >
          <Text marginTop={1}>{reportId ? "Aktualisieren" : "Einreichen"}</Text>
        </Button>
        {notTiny ? <Spacer /> : <Box height={3} />}
        <Button
          {...boringButtonProps}
          border="solid 2px"
          onClick={history.goBack}
        >
          <Text marginTop={1}>
            {mayModify(login?.role) ? "Zurück" : "Abbrechen"}
          </Text>
        </Button>
        {reportId == null || login?.role !== "master" ? null : (
          <Button
            isLoading={deleteMutation.isLoading}
            {...dangerButtonProps}
            rightIcon={<DeleteIcon />}
            onClick={() => {
              deleteMutation.mutate(reportId, {
                onSuccess: () => {
                  toast({
                    status: "success",
                    title: t("report.delete.submitted"),
                    description: t("report.delete.submitted_body"),
                  });
                  setUndo(event);
                  history.push("/");
                },
              });
            }}
          >
            <Text marginTop={1}>{t("delete")}</Text>
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Box width="100%" backgroundColor="myellow.50">
      <Center>
        <Helmet>
          <title>Märlistadt-Programm: {event.who || "Neuer Eintrag"}</title>
        </Helmet>
        <VStack
          width="min(100vw, 40rem)"
          paddingX={2}
          fontSize={{ base: "md", md: "xl" }}
        >
          <form ref={zo.ref} style={{ width: "100%" }}>
            <OuterBox name="event.calendar">
              {kind === "music" ? (
                <Heading
                  size="xl"
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  as="h3"
                  width="100%"
                  paddingRight="auto"
                  textColor="gray.700"
                  paddingBottom={2}
                >
                  Musikalische Darbietung
                  <Input
                    type="hidden"
                    name={zo.fields.what()}
                    value="Musik in der Märlistadt"
                  />
                </Heading>
              ) : (
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "what")}
                />
              )}
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dz, "who")}
              />
              {kind === "other" ? (
                <>
                  <HStack width="100%" alignItems="baseline">
                    <Text
                      minWidth="6em"
                      marginTop={2}
                      fontSize={{ base: "xl", md: "2xl" }}
                    >
                      Wo?
                    </Text>
                    <Select
                      fontSize={{ base: "xl", md: "2xl" }}
                      isReadOnly={!editableMore}
                      key="user"
                      name={dz.fields.where()}
                      placeholder="🠋"
                      defaultValue={dz.data.where}
                      width="100%"
                      onChange={(e) =>
                        setWhere(e.currentTarget.value as EventSchema["where"])
                      }
                    >
                      {Object.keys(eventSchema.shape.where._def.schema.enum)
                        .filter((c) => c !== "")
                        .map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                    </Select>
                  </HStack>
                  {where === "Andere" ? (
                    <TextInput
                      isReadOnly={!editableMore}
                      {...stringDataZorm(dz, "elsewhere")}
                    />
                  ) : null}
                </>
              ) : kind === "hut" ? (
                <Input
                  type="hidden"
                  name={dz.fields.where()}
                  value="Märlihuus"
                />
              ) : (
                <Input
                  type="hidden"
                  name={dz.fields.where()}
                  value="Bühne Rathausplatz"
                />
              )}
              <DecemberCalendar dz={dz} />
            </OuterBox>
            <OuterBox name="event.booklet">
              <UploadableImage
                isReadOnly={!editableMore}
                key="img1"
                name="img1"
                varName="img1.file"
                imageFile={dz.data.img1}
                baseUrl={`${apiUrl}/image`}
                textName="img1.attribution"
                text={dz.data.img1.attribution}
                landscape
                highRes
                download={mayModify(login?.role)}
              >
                <ErrorText error={dz.errors.img1()} />
              </UploadableImage>
              <UploadableImage
                isReadOnly={!editableMore}
                key="img2"
                name="img2"
                varName="img2.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={dz.data.img2}
                textName="img2.attribution"
                text={dz.data.img2?.attribution}
                landscape
                highRes
                optional
                download={mayModify(login?.role)}
              />
              <UploadableImage
                isReadOnly={!editableMore}
                key="logo"
                name="logo"
                varName="logo.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={icon ?? undefined}
                textName="logo.attribution"
                text={dz.data.logo?.attribution}
                setImageFile={setIcon}
                optional
                download={mayModify(login?.role)}
              />
              <InnerBox name="bookletText">
                <HelpText
                  name={icon === null ? "bookletTextLong" : "bookletTextShort"}
                />
                <CountingTextarea
                  isReadOnly={!editableMore}
                  min={
                    icon === null
                      ? 240
                      : 140 /* Zod enforces some lower limit as well in EventSchema (shared/src/event.ts) */
                  }
                  max={
                    icon === null
                      ? 340 /* Zod enforces some upper limit as well */
                      : 240
                  }
                  ignoreReturn={!mayModify(login?.role)}
                  width="100%"
                  height="18em"
                  name={zo.fields.desc()}
                  defaultValue={dz.data.desc}
                  {...textInputTheme}
                />
                <ErrorText error={zo.errors.desc()} />
              </InnerBox>
              <InnerBox name="links">
              <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "home")}
                />
                 <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "some")}
                />
              </InnerBox>
            </OuterBox>
            <OuterBox name="event.contact">
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "name")}
              />
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "tel")}
              />
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "mail")}
              />
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "street")}
              />
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "town")}
              />
              <Box width="100%" height="1px" backgroundColor="brand.100" />
              <HelpfulCheckbox
                isReadOnly={!editable}
                {...boolDataZorm(dzc, "future")}
              />
            </OuterBox>
            <OuterBox name="comments">
              <Textarea
                isReadOnly={!editable}
                width="100%"
                name={zo.fields.comment()}
                defaultValue={dz.data.comment}
                {...textInputTheme}
              />
            </OuterBox>
            {mayModify(login?.role) ? (
              <>
                <OuterBox name="Intern (OK)">
                  <TextInput {...stringDataZorm(dzi, "fee")} />
                  <TextInput {...stringDataZorm(dzi, "coupons")} />
                  <TextInput {...stringDataZorm(dzi, "travel")} />
                  <Textarea
                    width="100%"
                    name={zo.fields.internal.notes()}
                    defaultValue={dz.data.internal?.notes}
                    placeholder="Organisatorische Notizen"
                    {...textInputTheme}
                  />
                </OuterBox>
                <Discussion dz={dz} />
                <Assignment dz={dz} />
              </>
            ) : (
              <SmallPrint />
            )}
            <ValidationStatus zo={zo} />
            {validationButtons()}
          </form>

          <Box width="100%" height="5em" />
        </VStack>
      </Center>
    </Box>
  );
}
