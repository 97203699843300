import { Text, TextProps } from "@chakra-ui/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

/**
 * Optional help text, if indicated by the translation file
 * (name of the component with `@help` appended)
 * @param props
 * @returns
 */
export function HelpText(
  props: { name: string; colorScheme?: string; above?: boolean } & TextProps
) {
  const { name, colorScheme = "brand", above, ...rest } = props;
  const { t } = useTranslation("");

  if (i18next.exists(`${name}@help`)) {
    const text = t(`${name}@help`);
    if (text !== "" && text !== " ") {
      return (
        <Text
          fontSize={{ base: "xl", md: "2xl" }}
          fontStyle="italic"
          textColor={`${colorScheme}.800`}
          width="100%"
          paddingBottom={above ? 0 : 2}
          paddingTop={above ? 4 : 0}
          lineHeight="130%"
          {...rest}
        >
          {t(`${name}@help`)}
        </Text>
      );
    }
  }
  return null;
}
