import {
  FormControl,
  HStack,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { HelpText } from "./HelpText";
import { textInputTheme } from "../App";
import { MaybeTooltip } from "./MaybeTooltip";
import { ErrorText } from "./ErrorText";

/**
 * A labeled text input field with Zorm data/error support and tooltip/help options
 * @param props
 * @returns
 */
export function TextInput<T extends string>(
  props: {
    name: string;
    error?: z.ZodIssue;
    defaultValue?: T;
    hideLabel?: boolean;
    label?: string;
  } & InputProps
) {
  const {
    name,
    error,
    defaultValue,
    hideLabel,
    label,
    colorScheme = "brand",
    paddingLeft,
    ...rest
  } = props;
  const { t } = useTranslation("");

  return (
    <FormControl>
      <HStack>
        {hideLabel ? null : (
          <FormLabel
            minWidth="6em"
            marginTop={2}
            paddingLeft={paddingLeft}
            fontSize={{ base: "xl", md: "2xl" }}
          >
            {label ?? <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>}
          </FormLabel>
        )}
        <Input
          size={{ base: "sm", md: "md" }}
          type="text"
          name={name}
          defaultValue={defaultValue}
          spellCheck="true"
          lang="de"
          {...textInputTheme}
          {...rest}
        />
      </HStack>
      <HelpText name={name} colorScheme={colorScheme} />
      <ErrorText error={error} />
    </FormControl>
  );
}
