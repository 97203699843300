import { Box, HStack, StackProps, Tooltip } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import i18next from "i18next";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../App";

/**
 * A placeholder for a tool tip, if indicated in the translation
 * (name with `@tooltip` appended)
 * @param props
 * @returns
 */
export function MaybeTooltip(
  props: {
    name: string;
    children: ReactNode;
  } & StackProps
) {
  const { name, children, ...rest } = props;
  const { t } = useTranslation("");

  if (i18next.exists(`${name}@tooltip`)) {
    const text = t(`${name}@tooltip`);
    if (text !== "" && text !== " ") {
      return (
        <Tooltip label={text}>
          <HStack spacing={0} alignItems="baseline" {...rest}>
            <>{children}</>
            <Box paddingLeft={1} />
            <QuestionIcon
              boxSize="min(0.8em, 1.3rem)"
              color={theme.colors.brand["800"]}
            />
          </HStack>
        </Tooltip>
      );
    }
  }
  return <>{children}</>;
}
