import React from "react";
import {
  Box,
  Flex,
  FlexProps,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMenuOutline } from "react-icons/io5";

type Props = FlexProps & {
  logo?: React.ReactElement;
  switcher?: React.ReactElement;
  isOpen: boolean;
  onToggle?: () => void;
  onClose?: () => void;
};

export function NavigationBar(props: Props) {
  const { children, logo, switcher, isOpen, onToggle, onClose, ...rest } =
    props;
  const isClosed = useBreakpointValue({ base: false, md: true });

  // Catch moving to wider screen while menu is open
  // (otherwise, results e.g. in lack of visible button border)
  if (isClosed && isOpen) {
    onClose?.();
  }

  return (
    <Flex
      as="nav"
      width="100%"
      justify="space-between"
      align="center"
      wrap="wrap"
      paddingX={8}
      paddingY={6}
      {...rest}
    >
      {logo}
      <Spacer />
      {switcher}
      <Box display={{ base: "block", md: "none" }} onClick={onToggle}>
        {isOpen ? <IoIosCloseCircle size={24} /> : <IoMenuOutline size={24} />}
      </Box>
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          wrap="wrap"
          justify={["flex-end", "flex-end", "flex-end", "flex-end"]}
          align={["end", "end", "center", "center"]}
        >
          {children}
        </Flex>
      </Box>
    </Flex>
  );
}
